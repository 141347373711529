@import '../../../scss/theme-bootstrap';

.content-block-tout {
  &__media {
    position: relative;
  }
  &__line {
    margin-top: 10px;
    &--title {
      @include headline--primary;
      font-size: 26px;
      letter-spacing: 0;
      .style--bare {
        font-family: $optimaregular;
      }
      @media #{$large-up} {
        font-size: 26px;
      }
      .content-block-tout__text--subheader & {
        margin-top: 17px;
      }
    }
    &--subtitle {
      .style--bare {
        @include headline--senary;
      }
    }
    &--eyebrow,
    &--subheader {
      .style--bare {
        @include text--short;
      }
      margin: 7px 0 0;
      p {
        font-weight: bold;
      }
    }
    p {
      font-size: inherit;
      font-family: inherit;
      letter-spacing: inherit;
      line-height: inherit;
      &:only-of-type {
        margin: 0;
      }
    }
  }
  &__cta {
    margin: 15px 0;
    .link {
      font-weight: bold;
      text-decoration: underline;
    }
    a {
      font-family: inherit;
      &.link--bold {
        @include link--bold;
        color: $color-navy;
        background: none;
        padding: 0;
        &.custom--color {
          color: inherit;
        }
      }
      &.button-gray--white-font {
        background-color: $color-periwinkle-blue--aerin;
        color: $color-white;
      }
      &.button-navy--white-font {
        background-color: $color-navy;
        color: $color-white;
      }
      &.button-white--navy-font-border {
        background-color: $color-white;
        color: $color-navy;
        border: 1px solid $color-navy;
      }
      &.button-white--grey-font-border {
        background-color: $color-white;
        color: $color-periwinkle-blue--aerin-v2;
        border: 1px solid $color-periwinkle-blue--aerin;
      }
      &.button-black--gradient-border {
        background-color: $color-black;
        color: $color-white;
        border: 1px solid;
        border-image-slice: 1;
        font-weight: 500;
        border-image-source: linear-gradient(to left, $color-gold--renutriv, $color-gold--renutriv-v2);
      }
    }
    &.font--akzidenzregularbq,
    &.font--akzidenz-bold {
      a {
        font-family: $akzidenz;
        font-weight: bold;
      }
    }
  }
  &--inner-padding {
    .content-block-tout__text-alignment {
      padding: 0 22px;
    }
  }
  @mixin contentOverImage {
    .content-block-tout__inner {
      position: relative;
    }
    .content-block-tout__line--subtitle {
      .style--bare {
        @media #{$medium-up} {
          font-size: 23px;
        }
      }
    }
    .content-block-tout__text {
      position: absolute;
      text-align: center;
      top: 0%;
      #{$ldirection}: 0%;
      width: 100%;
      height: 100%;
      padding: 20px;
      @media #{$large-up} {
        padding: 30px;
      }
      &-placement {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-direction: column;
      }
      &-alignment {
        z-index: 2;
        max-width: 80%;
        @media #{$medium-up} {
          max-width: 70%;
        }
        @media #{$large-up} {
          max-width: 650px;
        }
      }
      &-wrapper {
        margin: 0;
      }
      .content-tout-text-align-left {
        text-align: #{$ldirection};
      }
      .content-tout-text-align-center {
        text-align: center;
      }
      .content-tout-text-align-right {
        text-align: #{$rdirection};
      }
    }
    .color-default {
      color: $color-white;
    }
    .content-block-tout__line {
      margin-top: 7px;
      &--title {
        @include headline--primary;
        margin-top: 0;
        font-size: 32px;
        @media #{$medium-up} {
          font-size: 45px;
        }
        .style--bare {
          font-family: $optimalight;
        }
      }
      &--subtitle {
        @include headline--quinary;
      }
    }
    &.content-block-tout--hover-button {
      .no-touch & {
        .content-block-tout__cta {
          opacity: 0;
          transition: all 0.2s;
        }
        &:hover .content-block-tout__cta {
          opacity: 1;
        }
      }
    }
  }
  @media #{$small-only} {
    &--content-over-image-mobile {
      @include contentOverImage;
    }
    .content-tout-text-align-left {
      text-align: #{$ldirection};
    }
    .content-tout-text-align-center {
      text-align: center;
    }
    .content-tout-text-align-right {
      text-align: #{$rdirection};
    }
  }
  @media #{$medium-up} {
    &--content-over-image {
      @include contentOverImage;
    }
  }
  @media #{$large-down} {
    .content-block-tout__small-medium-max-width--20 {
      max-width: 20%;
    }
    .content-block-tout__small-medium-max-width--30 {
      max-width: 30%;
    }
    .content-block-tout__small-medium-max-width--40 {
      max-width: 40%;
    }
    .content-block-tout__small-medium-max-width--50 {
      max-width: 50%;
    }
    .content-block-tout__small-medium-max-width--60 {
      max-width: 60%;
    }
    .content-block-tout__small-medium-max-width--70 {
      max-width: 70%;
    }
    .content-block-tout__small-medium-max-width--80 {
      max-width: 80%;
    }
    .content-block-tout__small-medium-max-width--90 {
      max-width: 90%;
    }
    .content-block-tout__small-medium-max-width--100 {
      max-width: 100%;
    }
  }
  @media #{$medium-up} {
    .content-block-tout__max-width--20 {
      max-width: 20%;
    }
    .content-block-tout__max-width--30 {
      max-width: 30%;
    }
    .content-block-tout__max-width--40 {
      max-width: 40%;
    }
    .content-block-tout__max-width--50 {
      max-width: 50%;
    }
    .content-block-tout__max-width--60 {
      max-width: 60%;
    }
    .content-block-tout__max-width--70 {
      max-width: 70%;
    }
    .content-block-tout__max-width--80 {
      max-width: 80%;
    }
    .content-block-tout__max-width--90 {
      max-width: 90%;
    }
    .content-block-tout__max-width--100 {
      max-width: 100%;
    }
  }
}
